
<script setup lang="ts">
    import { ref, computed, onMounted } from "vue";
    import { isMobile } from "o365.GlobalState.ts";

    export interface Props {
        text: string;
        href?: string;
        icon?: string;
        lookup?: boolean;
        disabled?: boolean;
        target?: Function;
    };

    const props = defineProps<Props>();

    /* refs */
    const el = ref<HTMLElement>();

    onMounted(() => {
        if (props.target && el.value) {
            props.target(el.value);
        }
    });
</script>

<template>
    <!-- mobile -->
    <template v-if="isMobile">
        <div class="d-flex align-items-center border-top">
            <a
                ref="el"
                class="flex-grow-1 d-flex justify-content-center align-items-center gap-2 fw-medium text-primary"
                :class="{ 'disabled': disabled }"
                style="padding: 0.75rem 1rem;"
                :href="href"
                v-bind="$attrs"
            >
                <span>{{ text }}</span>
                <i class="bi" :class="icon || 'bi-caret-down-fill'" style="font-size: 1.25em;" />
            </a>
        </div>
    </template>

    <!-- desktop -->
    <template v-else>
        <OButton variant="link" v-bind="{ text, href, lookup, disabled, target, ...$attrs }" />
    </template>
</template>
